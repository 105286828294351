import React from "react"
import { Link } from 'gatsby'

import Layout from "~components/layout/layout.component"
import SEO from "~components/seo/seo.component"

const PrivacyPolicyPage = () => (
    <Layout>
      <SEO title="Privacy Policy" />
      <h1>Privacy Policy</h1>
      <p>This statement applies to websites operated by or on behalf of RainedOut, RainedOut, LLC and its affiliated companies and subsidiaries.</p>
      <p>We value the trust you place in RainedOut, and maintain strict privacy policies. We are committed to providing our customers and users with a secure environment and state of the art technologies to safeguard your information. In the event that you terminate your customer relationship with us, or become an inactive customer, RainedOut will continue to adhere to the policies and practices described in this notice.</p>
      <h3>Information We Collect About You</h3>
      <p>When you visit our web site, and as your trusted notification utility, we collect, retain and use non-public personal information about our potential, current and former customers. We are likely to record two kinds of information:</p>
      <p>Non-personal statistical information collected in aggregate. This is anonymous information on subject such as: how many visitors come to our web site, how they navigate through the site, which browsers they use to visit the site, etc.</p>
      <p>Personal information that you knowingly supply to us. When you sign up for our products and services or register on our web site to access our product demo or to access your account information, RainedOut will collect personal information that will help us to identify you, authenticate you, and provide you with better service. This information is called "personally identifiable information" and is used to associate usage information with a specific individual or entity. Examples of personally identifiable information are: customer name, address, telephone number, email address, account number, etc.</p>
      <p>The only personally identifiable information we have was supplied to us by you.</p>
      <h3>What We Do With Your Information</h3>
      <p>RainedOut’s communication services enable millions of our customers to communicate with each other quickly and efficiently.    Some of our services are free:  for example - account set up, sending notifications and receiving email notifications are free, while SMS notifications carry a fee.</p>
      <p>We will also disclose personally identifiable information to government officials and law enforcement agencies when it is required by law (e.g., in compliance with a subpoena or court order) or if we believe in good faith that such action is appropriate and necessary to protect and defend the rights of RainedOut, or to protect the safety of RainedOut users and the general public. Because of the uncertain regulatory environment associated with privacy issues, we may also be forced to disclose personally identifiable and other information to the government.</p>
      <h3>We Protect Your Information</h3>
      <p>The protection of your personally identifiable information is of utmost importance to us. We maintain strict practices and procedures to safeguard your privacy in accordance with this privacy policy. We restrict employee access to customer information to only those who have a business reason to know such information, and we educate our employees about the importance of confidentiality and customer privacy.</p>
      <h3>Security</h3>
      <p>It is possible that Electronic Communications may be accessed by unauthorized third parties when communicated between you and RainedOut using the Internet, other network communications facilities, telephone or any other electronic means. All transfer of personal information on RainedOut.com and RainedOut.net is secured by encrypted user names and passwords, and the industry standard Secure Sockets Layer (SSL) protocol. SSL prohibits other web users from gaining access to your personal information, and to help protect the loss, misuse, and alteration of your information.</p>
      <h3>Policy Towards Children</h3>
      <p>Our Services are not directed to people under 13. If you become aware that your child has provided us with personal information without your consent, please contact us at contact@RainedOut.com. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we take steps to remove such information and terminate the child's account.</p>
      <h3>Changes to Privacy Policy</h3>
      <p>We reserve the right to change or update this Privacy Policy without notice.</p>
      <h3>Linked Web Sites</h3>
      <p>RainedOut web site contains links to various other web sites. Please be aware that these other sites may have privacy policies that differ from RainedOut's. RainedOut is in no way responsible for either the content that appears on linked sites, or your privacy once you have linked to other sites.</p>
      <h3>Opt Out</h3>
      <p>If you wish to opt out of RainedOut services:</p>
      <p>As an Administrator - log in to your account, go to Settings and scroll down to the Cancel Account.</p>
      <p>As a recipient or subscriber - visit <a href="https://www.rainedout.com">www.rainedout.com</a>, scroll down to the bottom of the homepage, click on Unsubscribe and follow the instructions from there.</p>
      <h3>California Consumer Privacy Act</h3>
      <p>As a RainedOut customer, please note that RainedOut does not sell data and does not receive any financial incentive on your data.  We collect fees from you or your organization to provide our services to you.</p>
      <p>The only personal information we have was supplied by you to us. You have the right to request to know the personal information we have.</p>
      <p>You also have the ability to see what information we have. If you are an Account Administrator, log in to see the information we have in your account.  By  logging in with your account id and password is the best way to verify your ownership of the account.</p>
      <p>If you are a message recipient or subscriber, go to <a href="https://www.rainedout.com">www.rainedout.com</a> and click on receive messages on the homepage.  Find your organization and enter your number or email.  We will send a validation code to that mobile number or email address.  Enter this code and we will display the information we have about you.  This is how we verify who you are.</p>
      <p>You also have the right to request the deletion of your data.  You can delete that data yourself.  Please refer to the Opt Out section directly above this one to learn how you can remove the data for you or your account.</p>
      <p>You will not receive discriminatory treatment for exercising your privacy rights conferred by the CCPA.</p>
      <p>You may designate an authorized agent to make a request on your behalf.</p>
      <h3>Additional Information</h3>
      <p>Questions regarding this Privacy Policy or the security practices of RainedOut should be directed to RainedOut Privacy and Security Administrator by sending an email to <a href="mailto:contact@rainedout.com">contact@rainedout.com</a> or calling our toll free number 800-230-1933.</p>
      <p>This Privacy Policy is effective as of January 01, 2020</p>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )

export default PrivacyPolicyPage
